import React from "react"
import { StaticQuery, graphql } from "gatsby"

// components
import ProjectCard from "./cards/project"

const Projects = () => (
  <StaticQuery
    query={graphql`
      query ProjectListQuery {
        projects: allMarkdownRemark(filter: {frontmatter: {project: {eq: true}}}, sort: {fields: frontmatter___date, order: DESC}) {
          edges {
            node {
              frontmatter {
                date(formatString: "DD MMMM YYYY", locale: "fr")
                title
                url
                color
                description
                thumb
              }
              id
            }
          }
        }
      }
    `}
    render={data => (
      <section className="project-list">
        {data.projects.edges.map(({ node }) => (
          <ProjectCard
            key={node.id}
            styleClass="project-card"
            title={node.frontmatter.title}
            description={node.frontmatter.description}
            url={node.frontmatter.url}
            img={node.frontmatter.thumb}
          />
        ))}
      </section>
    )}
  />
)

export default Projects