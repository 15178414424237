import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";

// Note: You can change "images" to whatever you'd like.

const Image = props => (
  <StaticQuery
    query={graphql`{
  images: allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
    edges {
      node {
        relativePath
        name
        absolutePath
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
}
`}
    render={data => {
      const image = data.images.edges.find(n => {
        return n.node.absolutePath.includes(props.filename);
      });
      if (!image) {
        return null
      }

      //const imageSizes = image.node.childImageSharp.sizes; sizes={imageSizes}
      return (
        <GatsbyImage
          placeholder={props.placeholder}
          backgroundColor={props.backgroundColor}
          image={image.node.childImageSharp.gatsbyImageData}
          style={props.style}
          imgStyle={props.imgStyle}
          alt={props.alt} />
      );
    }}
  />
)

export default Image;