import React from "react"

// Components
import Image from "../../utils/image"

// Markup
const ProjectCard = ({ styleClass, title, description, url, img }) => {
  return (
    <article className={`${styleClass}`}>
       <a className={`${styleClass}__container`} href={url}>
        <div className= {`${styleClass}__img`}>
          <Image 
            style={{width: "100%", height: "100%" , position: "absolute", zIndex: `-5`}}
            imgStyle={{objectPosition: "center center"}}
            alt={title} filename={img} />
        </div>
        <div className={`${styleClass}__content`}>
          <h4 className={`${styleClass}__title`}>{title}</h4>
          <p className={`${styleClass}__description`}>{description}</p>
          <p className={`${styleClass}__more`}>En savoir plus</p>
        </div>
       </a>
    </article>
  )
}
export default ProjectCard
