import React from "react"

// Components
import Projects from "../partials/projects"
import Footer from "../layout/footer"
import Back from "../partials/svg/back"

// Markup
const Project = () => {
  return (
    <div id="project">
        <div className="main-page-side top">
        <Back url="/" styleClass="back-link--black" text="accueil"/>
          <section className="main-page-title">
            <h1>Mes projets</h1>
            <p>Mes projets, en veux-tu? En voila!</p>
          </section>
          <Footer />
        </div>
        <div className="main-page-side bottom">
          <section className="main-page-projects">
            <Projects />
            <div className="footer-dark">
              <Footer />
            </div>
          </section>
        </div>
    </div>
  )
}
export default Project
